﻿/// <reference path="_utilities.scss" />

@keyframes progress-bar {
    0% {
        transform: translate(-100%, 0) scale(1, 1);
    }
    100% {
        transform: translate(175%, 0) scale(0, 1);
    }
}

.is-linear {
    &-for-indeterminate {
        .fs-loading-foreground {
            @extend %animate-progress-bar;
            animation-direction: normal;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }
    }
    &-alternate-for-indeterminate {
        .fs-loading-foreground {
            @extend %animate-progress-bar;
            animation-direction: reverse;
            animation-duration: 2.75s;
            animation-fill-mode: backwards;
        }
        .fs-bar {
            right: 0;
        }
    }
}

.fs {
    &-bar {
        bottom: 0;
        height: 5px;
        left: 0;
        position: absolute;
        top: 0;
    }
    &-loading {
        &-progress-bar {
            position: relative;
            top: -1.9rem;
        }
        &-container {
            display: block;
            height: 10px;
            overflow: hidden;
            position: relative;
            width: 100%;
            margin-top: -10px;
        }
        &-background {
            background-color: $wk-blue-tint3;
            width: 100%;
        }
        &-foreground {
            background-color: $wk-blue-shade1;
            width: 50%;
        }
    }
}

ng-view.ng-enter,
[ng-view].ng-enter {
    opacity: 0;
    transition: opacity 500ms;
}

ng-view.ng-enter-active,
[ng-view].ng-enter-active {
    opacity: 1;
}

.wk-layout-centered-large,
.wk-layout-centered-medium,
.wk-layout-centered-small,
.wk-layout-50-50,
.wk-layout-66-33,
.wk-layout-33-66,
.wk-layout-33-33-33 {
    transition: max-width 1s ease-out;
}