﻿/// <reference path="_utilities.scss" />

.action-item {
    pointer-events: none;
    opacity: 0;
}

.table-navbar {
    .navbar-right > .grid {
        padding-right: 8px;
    }
    .wk-nav > li > a {
        background-color: transparent;
        font-size: 1.25rem;
        > span::before {
            color: $wk-blue-shade1;
            font-size: 1.25em;
        }
        &:hover {
            background-color: transparent;
            > span::before {
                color: $wk-blue;
            }
        }
    }
}

table {
    th {
        @include screen-1201-and-greater {
            font-size: initial;
        }
        @include screen-xs {
            font-size: 10px
        }
        > a {
            border-bottom: 1px solid transparent;
        }
        [wk-icon="file-down"] {
            margin-left: 0;
            margin-right: 0;
            @include screen-1201-and-greater {
                font-size: 1.3rem;
            }
            @include screen-sm {
                font-size: .9rem;
            }
            @include screen-xs {
                font-size: .8rem;
            }
            [wk-icon="chevron-down"], 
            [wk-icon="chevron-up"] {
                margin-left: -3px;
                @include screen-sm {
                    margin-left: -4px;
                }
            }
        }
    }
    tr {
        min-height: 2.6rem;
    }
    td, 
    td a {
        word-wrap: break-word;
    }
    [wk-icon] {
        font-size: 16px; // for IE11
        font-size: initial;
    }
    @include screen-1201-and-greater {
        font-size: 15px;
    }
    @include screen-lg {
        font-size: 14px;
    }
    @include screen-md {
        font-size: 13px;
    }
    @include screen-sm {
        font-size: 11px;
    }
    @include screen-up-to-599 {
        font-size: 11px;
    }
    tbody td .user-email-link {
        display: block;
        position: relative;
        > a {
            display: block;
            visibility: hidden;
            padding-left: 20%;
            width: 100%;
            position: absolute;
            text-align: left;
            @include screen-xs {
                padding-left: 10%;
                width: 100%;
            }
            &:hover {
                border-bottom: 0;
            }
        }
    }
    tbody tr:hover .user-email-link > a {
        visibility: visible;
    }
    tbody {
        [wk-icon] {
            background: $wk-gray-tint6;
            color: $wk-gray;
            font-size: 1.5rem;
            height: 120%;
            margin-left: 0;
            margin-right: 0;
            margin-top: -3px;
            top: 0;
            width: 100%;
            @include screen-xs {
                font-size: 1rem;
            }
        }
        .file-actions {
            position: relative;
            [wk-icon] {
                height: 16px;  // for IE11
                height: initial;
                margin-left: 0;
                margin-right: 0;
                position: absolute;
                top: 9px;
                width: 100%;
                @include screen-1201-and-greater {
                    right: 2%;
                }
                @include screen-lg {
                    right: 2%;
                }
                @include screen-md {
                    right: 2%;
                }
                @include screen-sm {
                    right: 130%;
                    width: 1.2em;
                }
                @include screen-xs {
                    right: 110%;
                    top: 11px;
                    width: 1.2em;
                }
                &[wk-icon="file-cancel"] {
                    top: 10px;
                }
            }
        }
    }
    th span[wk-icon="chevron-down"], 
    th span[wk-icon="chevron-up"] {
        font-size: 1rem;
        margin-left: -1px;
        @include screen-md {
            font-size: .75rem;
            font-weight: 600;
            margin-top: -1px;
        }
        @include screen-sm {
            font-size: .6rem;
            font-weight: 600;
        }
        @include screen-xs {
            font-size: .5rem;
        }
    }
}

.table {
    > thead > tr {
        > th + th {
            padding-left: 0;
        }
    }
    > tbody > tr {
        > td + td {
            padding-left: 0;
        }
        > td[headers="select-all-checkbox"] {
            padding: 8px 0;
        }
        > td[headers=name] {
            font-size: 14px;
            padding-left: 0;
            word-break: break-word;
            @include screen-md {
                font-size: 13px;
            }
            @include screen-sm {
                font-size: 12px;
            }
            @include screen-up-to-599 {
                font-size: 11px;
            }
        }
        > td[headers=upload-date-time],
        > td[headers=size] {
            font-size: 15px;
            @include screen-lg {
                font-size: 14px;
            }
            @include screen-md {
                font-size: 13px;
            }
            @include screen-sm {
                font-size: 12px;
            }
            @include screen-up-to-599 {
                font-size: 11px;
            }
        }
        > td[headers="download-count"] {
            padding-right: 0;
            text-align: center;
        }
        > td.hidden-sm.hidden-xs + td[headers="user-first-name"] {
            @include screen-up-to-991 {
                padding-left: 8px;
            }
        }
        > td[headers="user-is-selected"] {
            padding: 8px 0;
            text-align: center;
        }
    }
}

.wk-search .wk-select-field {
    @include screen-600-and-greater {
        width: 14rem;
    }
}

#select-all-checkbox {
    padding: 8px 0;
    input[type='checkbox'] {
        padding: 0;
        margin: 0;
    }
    width: 3%;
    @include screen-sm {
        width: 4%;
    }
    @include screen-xs {
        width: 7%;
    }
}

#name {
    width: 45%;
    .public-file-list &,
    .received-files-list &,
    .shared-files-list & {
        width: 57%;
    }
}

#upload-date-time {
    width: 13%;
    @include screen-xs {
        width: 18%;
    }
}

#size {
    width: 8%;
    @include screen-xs {
        width: 12%;
    }
}
#author {
    width: 12%;
    @include screen-xs {
        width: 16%;
    }
}
#assigned-to {
    width: 12%;
    @include screen-xs {
        width: 14%;
    }
}

#download-count {
    padding-right: 0;
    text-align: left;
    width: 4%;
    a {
        width: 100%;
    }
}

#file-actions {
    text-align: center;
    width: 3%;
}

#file-list-table {
    table-layout: fixed;
    width: 100%;
    input[type='checkbox'] {
        padding: 0;
        margin: 2px 0 0 8px;
        @include screen-sm {
            margin-left: 2px;
        }
    }
    th input[type='checkbox'] {
        @include screen-md {
            margin-top: 3px;
        }
        @include screen-sm {
            margin-top: 1px;
        }
    }
}

.table > thead > tr > th {
    vertical-align: top;
    border-top: none;
    border-bottom: 1px solid $wk-gray-tint2;
    text-transform: uppercase;
}

fs-user-list-user-type select {
    padding: 4px 4px 6px;
    margin-bottom: -6px;
    margin-top: -4px;
}

#user-first-name,
#user-last-name {
    width: 15%;
}
#user-email {
    width: 28%;
}
#user-actions {
    width: 14%;
}
#user-type {
    padding-bottom: 8px;
    padding-top: 8px;
    width: 14%;
}
#user-is-active,
#user-can-upload {
    text-align: center;
    width: 8%;
}

#user-list-table {
    thead {
        th + th {
            padding-left: 0;
        }
    }
    tbody {
        [wk-icon] {
            padding-right: 10px;
            width: 32px;
            @include screen-xs {
                width: 22px;
            }
        }
    }
}

#sharing-options-table {
    #user-is-selected {
        padding: 8px 0;
        text-align: center;
        width: 9%;
    }
    #user-first-name {
        width: 24%;
        @include screen-up-to-991 {
            padding-left: 8px;
        }
    }
    #user-last-name {
        width: 24%;
    }
    #user-email {
        width: 43%;
    }
}

#file_reassign_owner_table #name,
#file_list_table_2 #name_2 {
    width: 50%;
}

#file_reassign_owner_table #author,
#file_list_table_2 #assigned-to {
    width: 50%;
}

#file_reassign_owner_table td[headers="name"],
#file_list_table_2 td[headers="name_2"] {
    padding-left: 8px;
}

td[headers="user-is-active"],
td[headers="user-can-upload"] {
    text-align: center;
}

.list-item {
    .user-actions, .file-actions {
        .list-action {
            opacity: 0.0;
        }
        .list-action-disabled {
            opacity: 0.0;
        }
        .display-list-action {
            opacity: 1.0;
        }
        .display-list-action-disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        [wk-icon] {
            background: none;
        }
    }
    &:hover {
        .user-actions, .file-actions {
            .list-action {
                opacity: 1.0;
            }
            .list-action-disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}

#file-bulk-delete-dialog 
{
    .file-list{
        overflow-y: scroll;
        max-height: 200px;
    }
}