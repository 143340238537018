﻿/// <reference path="_config.scss" />

// ----- Placeholders
%animate-progress-bar {
    animation-name: progress-bar;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: cubic-bezier(.25,.77,.77,.28);
}

// ----- Mixins
@mixin screen-1201-and-greater {
    @media screen and ( #{$screen-1201-and-greater} ) {
        @content;
    }
}

@mixin screen-lg {
    @media screen and ( #{$screen-lg-floor} ) and ( #{$screen-lg-ceiling} ) {
        @content;
    }
}

@mixin screen-md {
    @media screen and ( #{$screen-md-floor} ) and ( #{$screen-md-ceiling} ) {
        @content;
    }
}

@mixin screen-up-to-991 {
    @media screen and ( #{$screen-md-ceiling} ) {
        @content;
    }
}

@mixin screen-600-to-1040 {
    @media screen and ( #{$screen-600-and-greater} ) and ( #{$screen-up-to-1040} ) {
        @content;
    }
}

@mixin screen-600-and-greater {
    @media screen and ( #{$screen-600-and-greater} ) {
        @content;
    }
}

@mixin screen-sm {
    @media screen and ( #{$screen-sm} ) {
        @content;
    }
}

@mixin screen-up-to-599 {
    @media screen and ( #{$screen-up-to-599} ) {
        @content;
    }
}

@mixin screen-xs {
    @media screen and ( #{$screen-xs} ) {
        @content;
    }
}