﻿/// <reference path="_utilities.scss" />
[wk-icon] {
    font-size: 1.3rem;
    vertical-align: middle;
}

[wk-icon="spinner"] {
    font-size: 3rem;
}

[wk-icon="info"],
[wk-icon="check"] {
    font-size: 1rem;
}

[wk-icon].disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.noborder {
    border: none;
}

.note-editor > *, 
.note-editable > * {
    font-family: Helvetica, Ariel, sans-serif;
    font-size: 14px;
}

.page-title {
    .plain {
        font-family: $wk-sans-serif;
        font-weight: 300;
        font-size: 1.8rem;
        font-style: normal;
    }
    h1 {
        padding-left: 1rem;
    }
    display: block;
}

.page-subtitle {
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.text-bold {
    font-weight: bold !important; 
}

.text-normal {
    font-weight: 400;
}

.top-buffer {
    margin-top: 20px;
}

.tooltip-inner {
    max-width: 540px;
    word-break: break-word;
}

.cursor-pointer {
    cursor: pointer;
}

.center-block {
    margin-top: 30px;
}

.input-group-addon {
    border: 0;
    background-color: transparent;
}

.alert-dismissable button.close {
    padding: 0 5px !important;
    text-shadow: none !important;
}

.div-disabled
{
  pointer-events: none;
  opacity: 0.5;
}

.fs {
    &-action-bar {
        z-index: 999;
    }
    &-addon {
        height: 4.6rem;
    }
    &-form-group {
        margin-bottom: 2rem;
    }
    &-loading-icon {
        left: 48.2vw;
        position: absolute;
        top: 155px;
        z-index: 1000;
    }
    &-search {
        width: 100%;
    }
    &-space {
        margin-bottom: 2rem;
    }
    &-firm-name {
        padding-bottom: .35rem;
        &:focus, &:hover {
            border-bottom: 0;
        }
    }
    &-text-overflow {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.fs-flex {
    display: flex;
    flex-direction: row;
    &-small-block {
        display: flex;
        flex: 0 0 53px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 12px;
        &:first-child {
            margin-left: 0;
        }
    }
    &-medium-block {
        display: flex;
        flex: 0 0 80px;
        margin-left: 15px;
        margin-right: 0;
        margin-top: 12px;
    }
    &-large-block {
        display: flex;
        flex: 0 0 126px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 12px;
    }
    &-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.fs-input-group {
    height: 3.2rem;
    position: relative;
    top: -8px;
    &-addon {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        padding-left: 0;
        text-align: left;
    }
    .wk-field-header,
    .wk-field-body {
        display: inline-block;
    }
}

.fs-user-type {
    .radio-inline {
        @include screen-sm {
            display: block;
            margin: .5rem 0 .5rem 10px;
        }
    }
}

.wk-layout-centered-large {
    .preview-area  {
        @include screen-600-to-1040 {
            max-width: 42.5rem;
        }
    }
    &.is-wider {
        @include screen-600-to-1040 {
            padding-left: 0;
            padding-right: 0;
            max-width: 45rem;
            font-size: 1.385rem;
        }
    }
}

.wk-select-field > select.fs-flex-select {
    background-color: $wk-white;
    border: 1px solid $wk-gray-tint4;
    border-radius: 0;
    color: $wk-gray;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.5;
    padding: 0.6875rem 1rem;
    width: initial;
    display: flex;
    flex: 0 0 80px;
    font-family: $wk-sans-serif;
    min-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:focus {
        border-color: $wk-gray;
    }
    &-large {
        flex: 1 0 180px;
        min-width: 180px;
        &:focus {
            border-color: $wk-gray;
        }
    }
}

.login-container {
    background: $wk-white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding: 20px;
    width: 400px;
}

.wk-login-footer {
    font-size: 92%;
    line-height: 1.8;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding: .5em;
}

.flexbox {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 1000px;
    &-item {
        display: flex;
        flex: 1 1 120px;
        align-items: center;
        height: auto;
        margin-left: 5px;
        margin-right: 15px;
        @include screen-1201-and-greater {
            height: 120px;
        }
        @include screen-lg {
            height: 120px;
        }
        &.firm-name {
            flex-wrap: wrap;
        }
        &:first-child {
            align-self: center;
            min-width: 120px;
            @include screen-1201-and-greater {
                height: 120px;
            }
            @include screen-lg {
                height: 120px;
            }
            @include screen-md {
                height: 120px;
                max-height: 16vw;
            }
            @include screen-sm {
                max-height: 20vw;
            }
            @include screen-up-to-599 {
                max-height: 20vw;
            }
        }
    }
}

.fs-error-graphic {
    margin-right: 20px;
    margin-top: 21px;
    width: 155px;
    @include screen-600-and-greater {
        float: right;
    }
    @include screen-up-to-599 {
        background: $wk-gray-tint6;
        margin-bottom: 10px;
        margin-right: 0;
        margin-top: -20px;
        max-height: 246px;
        padding-bottom: 30px;
        padding-top: 30px;
        width: initial;
    }
}

.file-name {
    display: inline-block;
    word-break: break-all;
    overflow-wrap: break-word;
}

.wk-pagination > li > a {
    padding: .5625rem 1.25rem .7rem 1.25rem;
    &:hover, &:active {
        text-decoration: underline;
        border-bottom: none;
    }
}

.fs-label {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 5px;
}

input:-ms-input-placeholder {
  color: inherit;
  opacity: .5;
}
