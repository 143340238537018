﻿/// <reference path="_utilities.scss" />
@font-face {
  font-family: 'Bliss 2';
  src: url('../fonts/Bliss2/Bliss2L.otf') format('otf');
}

.wk-header > .wk-header-container {
    max-height: 152px;
    padding: 1rem 0;
    > .flexbox {
        min-height: 120px;
    }
    @include screen-md {
        height: 152px;
        max-height: 21vw;
    }
    @include screen-sm {
        height: 152px;
        max-height: 32.25vw;
    }
    @include screen-up-to-599 {
        height: 140px;
        max-height: 35.25vw;
        min-height: 3.5rem;
        padding: .625rem .25rem;
    }
}

.wk-header .wk-nav {
    @include screen-sm {
        margin-top: 0;
    }
}

.logo {
    position: relative;
}

a.fs-logo:hover, 
a.fs-logo:focus {
    border: none;
}

.logo-mark {
    height: auto;
    width: auto;
}

.wk-mark {
    height: 120px;
    min-height: initial;
    padding: 15px;
    @include screen-sm {
        margin-top: 1vw;
    }
}

.wk-firm-name {
    font-family: "Bliss 2", Trebuchet, sans serif;
}

.aside-nav {
    position: absolute;
    top: 90px;
    right: .5rem;
    z-index: 1001;
    [wk-icon] {
        font-size: 1rem;
    }
    @include screen-sm {
        top: -5px;
        font-size: .8rem
    }
}

.wk-header {
    .wk-nav.open > li > a {
        @include screen-sm {
            min-width: 200px;
            position: relative;
            left: -111px;
        }
    }
    .aside-nav .wk-nav > li {
        [wk-icon] {
            @include screen-sm {
                margin-right: 0.25rem;
                margin-top: -1px;
            }
            @include screen-md {
                margin-right: 0.25rem;
                margin-top: -1px;
            }
        }
    }
    > a {
        @include screen-sm {
            padding: 0.625rem 0.325rem .625rem 1rem;
        }
        @include screen-md {
            padding: 0.625rem 0.325rem;
        }
    }
    .wk-more-menu a {
        @include screen-sm {
            font-size: .8rem;
        }
    }
}