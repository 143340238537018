﻿// ----- Colors
$fs-blue: #01446D;

$wk-blue: #007AC3;
$wk-blue-shade1: #005B92;
$wk-blue-tint1: #409BD2;
$wk-blue-tint2: #80BDE1;
$wk-blue-tint3: #A6D1EA;

$wk-green: #85BC20;

$wk-gray: #474747;
$wk-gray-tint1: #757575;
$wk-gray-tint2: #A3A3A3;
$wk-gray-tint3: #BFBFBF;
$wk-gray-tint4: #DADADA;
$wk-gray-tint5: #EDEDED;
$wk-gray-tint6: #F6F6F6;

$wk-red: #E5202E;

$wk-white: #FFFFFF;

// ---- Fonts
$wk-sans-serif: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
$wk-serif: "Franziska", "Times New Roman", Times, serif;
$wk-monospace: "DejaVu Sans Mono", Consolas, Menlo, Monaco, "Lucida Console", "Bitstream Vera Sans Mono", "Courier New", monospace;

// ----- Media Query Break Points

// ----- Based on Bootstrap
$screen-xs          : "max-width: 480px";
$screen-sm          : "max-width: 767px";
$screen-md-floor    : "min-width: 768px";
$screen-md-ceiling  : "max-width: 991px";
$screen-lg-floor    : "min-width: 992px";
$screen-lg-ceiling  : "max-width: 1200px";

// ----- Based on WK CSS (1rem = 16px)
$screen-up-to-599        : "max-width: 37.4375rem";
$screen-600-and-greater  : "min-width: 37.5rem";
$screen-up-to-1040       : "max-width: 65rem";
$screen-1201-and-greater : "min-width: 75.0625rem";