﻿/// <reference path="_utilities.scss" />
.wk-footer-container {
    nav {
        margin: 0 auto;
    }
    ul {
        padding-left: 0;
        padding-right: 0;
    }
    li {
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
        list-style-type: none;
        display: inline-block;
        @include screen-sm {
            display: block;
            margin: 0.5rem 0;
            text-align: center;
        }
        &:first-child {
            margin-left: 0;
        }
        a {
            color: $wk-white;
            &:hover {
                color: $wk-gray-tint6;
            }
        }
    }
}
.wk-footer-copyright-container {
    color: $wk-gray;
    font-weight: 400;
}
.one-trust-preference {
    margin-top: 10px;
}