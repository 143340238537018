﻿/// <reference path="_utilities.scss" />
.fs-dropdown-open {
    position: absolute;
    display: block;
}

#navigation-item-upload-file {
    background-color: $fs-blue;
    &:hover {
        background-color: $wk-blue-shade1;
    }
    span {
        font-size: 2rem;
        margin: -5px auto;
    }
}
.wk-navbar.table-navbar, 
.wk-navbar .wk-navbar-container.table-navbar-container {
    background-color: transparent;
}

.wk-navbar {
    margin-bottom: 40px;
}

.wk-nav > li > a {
    display: block;
    font-size: .9375rem;
    padding: .75rem .9375rem;
    &#navigation-item-files, 
    &#navigation-item-manage {
        cursor: default;
    }
}

.wk-navbar-dropdown-column {
    @include screen-600-and-greater {
        min-width: 200px;
    }
}

.wk-header .wk-nav > li, 
.wk-header .wk-more-menu > li {
    @include screen-600-and-greater {
        padding: 0;
    }
}

.wk-header .wk-nav > li > a {
    padding: .625rem .325rem .325rem 0;
    margin-right: 20px;
}

.wk-navbar .wk-nav .wk-navbar-dropdown .wk-dropdown-column-list a:hover,
.wk-navbar .wk-nav .wk-navbar-dropdown [wk-dropdown-column*='list'] a:hover {
    text-decoration: none;
}

.wk-nav > li:focus .wk-navbar-dropdown-container {
    display: block;
    left: 0;
    position: relative;
    width: 100%;
}

.wk-nav > li:focus {
    position: relative;
    .wk-navbar-dropdown-container {
        @include screen-600-and-greater {
            position: absolute;
        }
    }
}

.wk-nav > li:focus .wk-navbar-dropdown-container.wk-navbar-dropdown-classic {
    left: inherit;
    width: auto;
}

.wk-navbar .wk-nav > li:focus > a {
    background-color: #005b92;
}
