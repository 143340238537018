// Fix dropdown open/close issue in AngularJS UI Bootstrap with Bootstrap 4.
// https://stackoverflow.com/questions/51010306/angularjs-ui-bootstrap-dropdown-button-with-bootstrap-4
.dropdown.open .dropdown-menu {
  display: block;
}

// Change default hover color.
.table-hover tbody tr:hover {
  background: $wk-gray-tint6;
}

// Adjust growl message position.
.growl-container > .growl-item {
  padding-right: 10px;
}

// Adjust growl close icon position.
.growl-container button.close {
  margin-top: -4px;
}

// Fix Summernote dropdown as it does not work with Bootstrap 5.
// https://github.com/summernote/summernote/issues/4170
.note-editor .dropdown-toggle::after {
  all: unset
}

.note-editor .note-dropdown-menu {
  box-sizing: content-box 
}

.note-editor .note-modal-footer {
  box-sizing: content-box
}

.note-dropdown-item:hover {
  border-bottom: 0;
}

// Change list background color on hover for Bootstrap 5.
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: transparent;
}

// Adjust list bottom border for Bootstrap 5.
.wk-text-overflow {
  display: revert;
}

// Set navigation menu text color on hover for Bootstrap 5.
.wk-navbar .wk-navbar-container .wk-nav a:hover {
  color: #fff;  
}

// Adjust Insert Link button position in Summernote link dialog for Bootstrap 5.
.link-dialog > .note-modal-content > .note-modal-footer {
  box-sizing: border-box;
  height: 70px;
  border-top: 1px solid #e5e5e5;
}