﻿/// <reference path="_utilities.scss" />
.fs {
    &-logo {
        opacity: 1;
        left: 0;
        transition: opacity 500ms ease-in-out;
        &:hover {
            opacity: .5;
        }
    }
    &-firm-name {
        color: inherit;
        &:hover {
            color: lighten($wk-gray-tint1, 5%);
        }
        &:active, &:focus {
            color: inherit;
        }
    }
}

.firm-name {
    font-size: 3rem;
    line-height: 1;
    padding-left: 0;
    @include screen-sm {
        font-size: 2.5rem;
        margin-top: 1.325rem;
    }
    @include screen-xs {
        font-size: 8vw;
    }
    .preview-area & {
        font-size: 2.57rem;
        margin-top: 0;
        @include screen-sm {
            font-size: 2.14rem;
            margin-top: 0;
        }
        @include screen-xs {
            font-size: 6.86vw;
            margin-top: 0;
        }
    }
}

.preview-area {
    margin: 2.5rem 0;
    min-height: 150px;
    padding: .857rem 0;
    @include screen-md {
        max-height: 18vw;
    }
    @include screen-sm {
        max-height: 27.66vw;
    }
    @include screen-up-to-599 {
        height: 120px;
        max-height: 30.23vw;
        min-height: 3rem;
        padding: .536rem .214rem;
    }
    .logo-mark {
        height: auto;
        max-height: 102px;
    }
    .flexbox {
        align-items: center;
        height: 8.577vw;
        margin-left: 4.28px;
        margin-right: 4.28px;
        @include screen-1201-and-greater {
            height: 102px;
        }
        @include screen-lg {
            height: 102px;
        }
        &-item {
            align-self: flex-start;
            flex: 1 1 120px;
            transition: all 500ms;
            &:first-child {
                height: 8.577vw;
                @include screen-1201-and-greater {
                    height: 102px;
                }
                @include screen-lg {
                    height: 102px;
                    max-height: 8vw;
                }
                @include screen-md {
                    height: 102px;
                    max-height: 13.72vw;
                }
                @include screen-sm {
                    max-height: 17.15vw;
                }
                @include screen-up-to-599 {
                    max-height: 17.15vw;
                }
            }
            &:nth-child(2) {
                align-self: center;
                height: auto;
                justify-content: flex-start;
            }
        }
    }
}

.firm-url {
    &-control {
        .valid {
            color: $wk-green;
        }
        .invalid {
            color: $wk-red;
        }
    }
}