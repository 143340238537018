﻿/// <reference path="_utilities.scss" />
.drag-and-drop-hover {
    background-color: $wk-gray-tint4;
}

.picklist {
    input[type='text'] {
        cursor: text;
        font-size: .9rem;
        padding-right: 40px;
    }

    li {
        cursor: pointer;
        height: 25px;

        &:hover {
            background-color: $wk-gray-tint6;
            border-bottom: 1px solid #000000;
        }
    }

    ul {
        max-height: 260px;
        overflow: auto;
        width: 100%;
        font-size: 14px;
    }

    .dropdown-icon {
        cursor: pointer;
        float: right;
        font-size: 1.5rem;
        margin-top: -38px;
        margin-right: 6px;
        pointer-events: none;
        position: relative;
        z-index: 2;
    }

    ul:not(:hover) li.active {
        background-color: $wk-blue-tint2;
        color: $wk-blue;
    }
}

.upload-progress-bar {
    background-color: $wk-blue-tint1;
    height: 4px;
    margin-top: -4px;
    position: relative;
    z-index: 1;
}

#file-upload {
    text-align: center;
    &-status {
        display: block;
        overflow-y: auto;
        margin-top: -10px;
        max-height: 224px;
        &::-webkit-scrollbar {
            width: .6rem;
            &-thumb {
                background-color: $wk-gray;
            }
            &-track {
                background-color: $wk-gray-tint4;
            }
        }
    }
    .list-group-item {
        background-color: $wk-gray-tint6;
        border: 0;
        border-bottom: 4px solid $wk-gray-tint5;
        font-size: .9rem;
        margin-bottom: 0;
        line-height: 2.1;
        @include screen-up-to-599 {
            line-height: 1.2;
        }
        &-danger {
            background-color: lighten($wk-red, 44%);
            border-bottom-color: darken($wk-red, 20%);
            color: darken($wk-red, 20%);
        }
        &-info {
            background-color: lighten($wk-blue, 57%);
            border-bottom-color: darken($wk-blue, 20%);
            color: darken($wk-blue, 20%);
        }
        &-success {
            background-color: lighten($wk-green, 47%);
            border-bottom-color: darken($wk-green, 20%);
            color: darken($wk-green, 20%);
        }
        &:first-child {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
        &:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    & .file-name {
        width: 80%;

        @include screen-up-to-599 {
            font-size: .7rem;
        }
    }

    & [wk-icon] {
        cursor: default;
    }
}

#files-to-process, 
#files-being-uploaded,
#files-processed {
    font-family: $wk-sans-serif;
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
    vertical-align: middle;
}

#files-to-process {
    .hide-icon {
        color: $wk-gray;
        opacity: 0.2;
    }
    .icon {
        font-size: 2rem;
        line-height: 1rem;
        vertical-align: middle;
        &:hover {
            color: $wk-red;
        }
    }
    .list-group-item {
        border: 0;
        border-bottom: 4px solid $wk-gray-tint5;
        margin-bottom: 0;
        &:first-child {
            border-top: 0;
        }
        &:hover {
            background-color: $wk-gray-tint6;
        }
    }
}

#files-processed {
    .icon {
        float: right;
        font-size: 2rem;
        @include screen-up-to-599 {
            font-size: 1.2rem;
            position: relative;
            right: -12px;
        }
    }
}

#file-selection {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    label {
        font-size: 1rem;
        width: 100%;
    }
}

#file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

#file-drag-message {
    border: 1px dashed $wk-gray-tint4;
    border-bottom: 0;
    color: $wk-gray-tint1;
    margin-bottom: 0;
    padding: 2rem 0;
    [wk-icon="cloud-up"] {
        font-size: 4rem;
    }
}

.email-body-error {
    color: $wk-red;
    font-size: 0.87891rem;
    font-style: italic;
}