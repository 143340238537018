﻿/// <reference path="_utilities.scss" />
html {
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    background: $wk-white;
    min-width: 100%;
    min-height: 600px;
    font-family: $wk-sans-serif;
    font-weight: 300;
    line-height: 1.42857143;
    color: $wk-gray;
    font-size: 15px;
    overflow-y: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

button:-moz-focusring {
    box-shadow: 0 0 2px 1px #6B8DF7;
}
button, 
.wk-button, 
[wk-button] {
    border: 1px solid transparent;
    &:hover, 
    &:focus {
        border: 1px solid;
    }
    &:not([disabled]) {
        border: 1px solid transparent;
    }
    &:hover:not([disabled]) {
        border: 1px solid $wk-blue-shade1;
    }
}

main {
    min-height: 500px;
    margin-top: 2.1875rem;
}

a {
    text-decoration: none;
    &:hover, 
    &:focus {
        text-decoration: none;
        border-bottom: 1px solid;
    }
}

img {
    border: 0;
    height: auto;
    max-width: 100%;
}

h1, .h1, 
h3, .h3,
h5, .h5 {
    color: $wk-blue;
    font-family: $wk-serif;
    font-weight: 300;
    font-style: italic;
    line-height: inherit;
}

h1, .h1 {
    border-bottom: 1px solid $wk-gray-tint4;
    margin-bottom: 2rem;
}

h4, .h4 {
    font-weight: 400;
}

label {
    font-weight: 400;
}

p,
ul,
ol,
dl,
cite,
address,
blockquote {
    font-weight: 400;
}

textarea {
    font-family: $wk-sans-serif;
}

abbr[data-original-title], 
abbr[title] {
    border-bottom: 1px dotted $wk-gray-tint3;
}