.is-linear-alternate-for-indeterminate .fs-loading-foreground, .is-linear-for-indeterminate .fs-loading-foreground {
  animation-name: progress-bar;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: cubic-bezier(0.25, 0.77, 0.77, 0.28);
}

@keyframes progress-bar {
  0% {
    transform: translate(-100%, 0) scale(1, 1);
  }
  100% {
    transform: translate(175%, 0) scale(0, 1);
  }
}
.is-linear-for-indeterminate .fs-loading-foreground {
  animation-direction: normal;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.is-linear-alternate-for-indeterminate .fs-loading-foreground {
  animation-direction: reverse;
  animation-duration: 2.75s;
  animation-fill-mode: backwards;
}
.is-linear-alternate-for-indeterminate .fs-bar {
  right: 0;
}

.fs-bar {
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
}
.fs-loading-progress-bar {
  position: relative;
  top: -1.9rem;
}
.fs-loading-container {
  display: block;
  height: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: -10px;
}
.fs-loading-background {
  background-color: #A6D1EA;
  width: 100%;
}
.fs-loading-foreground {
  background-color: #005B92;
  width: 50%;
}

ng-view.ng-enter,
[ng-view].ng-enter {
  opacity: 0;
  transition: opacity 500ms;
}

ng-view.ng-enter-active,
[ng-view].ng-enter-active {
  opacity: 1;
}

.wk-layout-centered-large,
.wk-layout-centered-medium,
.wk-layout-centered-small,
.wk-layout-50-50,
.wk-layout-66-33,
.wk-layout-33-66,
.wk-layout-33-33-33 {
  transition: max-width 1s ease-out;
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  background: #FFFFFF;
  min-width: 100%;
  min-height: 600px;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.42857143;
  color: #474747;
  font-size: 15px;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

button:-moz-focusring {
  box-shadow: 0 0 2px 1px #6B8DF7;
}

button,
.wk-button,
[wk-button] {
  border: 1px solid transparent;
}
button:hover, button:focus,
.wk-button:hover,
.wk-button:focus,
[wk-button]:hover,
[wk-button]:focus {
  border: 1px solid;
}
button:not([disabled]),
.wk-button:not([disabled]),
[wk-button]:not([disabled]) {
  border: 1px solid transparent;
}
button:hover:not([disabled]),
.wk-button:hover:not([disabled]),
[wk-button]:hover:not([disabled]) {
  border: 1px solid #005B92;
}

main {
  min-height: 500px;
  margin-top: 2.1875rem;
}

a {
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  border-bottom: 1px solid;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

h1, .h1,
h3, .h3,
h5, .h5 {
  color: #007AC3;
  font-family: "Franziska", "Times New Roman", Times, serif;
  font-weight: 300;
  font-style: italic;
  line-height: inherit;
}

h1, .h1 {
  border-bottom: 1px solid #DADADA;
  margin-bottom: 2rem;
}

h4, .h4 {
  font-weight: 400;
}

label {
  font-weight: 400;
}

p,
ul,
ol,
dl,
cite,
address,
blockquote {
  font-weight: 400;
}

textarea {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
}

abbr[data-original-title],
abbr[title] {
  border-bottom: 1px dotted #BFBFBF;
}

@font-face {
  font-family: "Bliss 2";
  src: url("../fonts/Bliss2/Bliss2L.otf") format("otf");
}
.wk-header > .wk-header-container {
  max-height: 152px;
  padding: 1rem 0;
}
.wk-header > .wk-header-container > .flexbox {
  min-height: 120px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .wk-header > .wk-header-container {
    height: 152px;
    max-height: 21vw;
  }
}
@media screen and (max-width: 767px) {
  .wk-header > .wk-header-container {
    height: 152px;
    max-height: 32.25vw;
  }
}
@media screen and (max-width: 37.4375rem) {
  .wk-header > .wk-header-container {
    height: 140px;
    max-height: 35.25vw;
    min-height: 3.5rem;
    padding: 0.625rem 0.25rem;
  }
}

@media screen and (max-width: 767px) {
  .wk-header .wk-nav {
    margin-top: 0;
  }
}

.logo {
  position: relative;
}

a.fs-logo:hover,
a.fs-logo:focus {
  border: none;
}

.logo-mark {
  height: auto;
  width: auto;
}

.wk-mark {
  height: 120px;
  min-height: initial;
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .wk-mark {
    margin-top: 1vw;
  }
}

.wk-firm-name {
  font-family: "Bliss 2", Trebuchet, sans serif;
}

.aside-nav {
  position: absolute;
  top: 90px;
  right: 0.5rem;
  z-index: 1001;
}
.aside-nav [wk-icon] {
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .aside-nav {
    top: -5px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 767px) {
  .wk-header .wk-nav.open > li > a {
    min-width: 200px;
    position: relative;
    left: -111px;
  }
}
@media screen and (max-width: 767px) {
  .wk-header .aside-nav .wk-nav > li [wk-icon] {
    margin-right: 0.25rem;
    margin-top: -1px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .wk-header .aside-nav .wk-nav > li [wk-icon] {
    margin-right: 0.25rem;
    margin-top: -1px;
  }
}
@media screen and (max-width: 767px) {
  .wk-header > a {
    padding: 0.625rem 0.325rem 0.625rem 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .wk-header > a {
    padding: 0.625rem 0.325rem;
  }
}
@media screen and (max-width: 767px) {
  .wk-header .wk-more-menu a {
    font-size: 0.8rem;
  }
}

.fs-dropdown-open {
  position: absolute;
  display: block;
}

#navigation-item-upload-file {
  background-color: #01446D;
}
#navigation-item-upload-file:hover {
  background-color: #005B92;
}
#navigation-item-upload-file span {
  font-size: 2rem;
  margin: -5px auto;
}

.wk-navbar.table-navbar,
.wk-navbar .wk-navbar-container.table-navbar-container {
  background-color: transparent;
}

.wk-navbar {
  margin-bottom: 40px;
}

.wk-nav > li > a {
  display: block;
  font-size: 0.9375rem;
  padding: 0.75rem 0.9375rem;
}
.wk-nav > li > a#navigation-item-files, .wk-nav > li > a#navigation-item-manage {
  cursor: default;
}

@media screen and (min-width: 37.5rem) {
  .wk-navbar-dropdown-column {
    min-width: 200px;
  }
}

@media screen and (min-width: 37.5rem) {
  .wk-header .wk-nav > li,
  .wk-header .wk-more-menu > li {
    padding: 0;
  }
}

.wk-header .wk-nav > li > a {
  padding: 0.625rem 0.325rem 0.325rem 0;
  margin-right: 20px;
}

.wk-navbar .wk-nav .wk-navbar-dropdown .wk-dropdown-column-list a:hover,
.wk-navbar .wk-nav .wk-navbar-dropdown [wk-dropdown-column*=list] a:hover {
  text-decoration: none;
}

.wk-nav > li:focus .wk-navbar-dropdown-container {
  display: block;
  left: 0;
  position: relative;
  width: 100%;
}

.wk-nav > li:focus {
  position: relative;
}
@media screen and (min-width: 37.5rem) {
  .wk-nav > li:focus .wk-navbar-dropdown-container {
    position: absolute;
  }
}

.wk-nav > li:focus .wk-navbar-dropdown-container.wk-navbar-dropdown-classic {
  left: inherit;
  width: auto;
}

.wk-navbar .wk-nav > li:focus > a {
  background-color: #005b92;
}

.drag-and-drop-hover {
  background-color: #DADADA;
}

.picklist input[type=text] {
  cursor: text;
  font-size: 0.9rem;
  padding-right: 40px;
}
.picklist li {
  cursor: pointer;
  height: 25px;
}
.picklist li:hover {
  background-color: #F6F6F6;
  border-bottom: 1px solid #000000;
}
.picklist ul {
  max-height: 260px;
  overflow: auto;
  width: 100%;
  font-size: 14px;
}
.picklist .dropdown-icon {
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  margin-top: -38px;
  margin-right: 6px;
  pointer-events: none;
  position: relative;
  z-index: 2;
}
.picklist ul:not(:hover) li.active {
  background-color: #80BDE1;
  color: #007AC3;
}

.upload-progress-bar {
  background-color: #409BD2;
  height: 4px;
  margin-top: -4px;
  position: relative;
  z-index: 1;
}

#file-upload {
  text-align: center;
}
#file-upload-status {
  display: block;
  overflow-y: auto;
  margin-top: -10px;
  max-height: 224px;
}
#file-upload-status::-webkit-scrollbar {
  width: 0.6rem;
}
#file-upload-status::-webkit-scrollbar-thumb {
  background-color: #474747;
}
#file-upload-status::-webkit-scrollbar-track {
  background-color: #DADADA;
}
#file-upload .list-group-item {
  background-color: #F6F6F6;
  border: 0;
  border-bottom: 4px solid #EDEDED;
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 2.1;
}
@media screen and (max-width: 37.4375rem) {
  #file-upload .list-group-item {
    line-height: 1.2;
  }
}
#file-upload .list-group-item-danger {
  background-color: #fce9ea;
  border-bottom-color: #8e111a;
  color: #8e111a;
}
#file-upload .list-group-item-info {
  background-color: #e7f6ff;
  border-bottom-color: #003a5d;
  color: #003a5d;
}
#file-upload .list-group-item-success {
  background-color: #ebf8d4;
  border-bottom-color: #476511;
  color: #476511;
}
#file-upload .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
#file-upload .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#file-upload .file-name {
  width: 80%;
}
@media screen and (max-width: 37.4375rem) {
  #file-upload .file-name {
    font-size: 0.7rem;
  }
}
#file-upload [wk-icon] {
  cursor: default;
}

#files-to-process,
#files-being-uploaded,
#files-processed {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  text-align: left;
  vertical-align: middle;
}

#files-to-process .hide-icon {
  color: #474747;
  opacity: 0.2;
}
#files-to-process .icon {
  font-size: 2rem;
  line-height: 1rem;
  vertical-align: middle;
}
#files-to-process .icon:hover {
  color: #E5202E;
}
#files-to-process .list-group-item {
  border: 0;
  border-bottom: 4px solid #EDEDED;
  margin-bottom: 0;
}
#files-to-process .list-group-item:first-child {
  border-top: 0;
}
#files-to-process .list-group-item:hover {
  background-color: #F6F6F6;
}

#files-processed .icon {
  float: right;
  font-size: 2rem;
}
@media screen and (max-width: 37.4375rem) {
  #files-processed .icon {
    font-size: 1.2rem;
    position: relative;
    right: -12px;
  }
}

#file-selection {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}
#file-selection label {
  font-size: 1rem;
  width: 100%;
}

#file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#file-drag-message {
  border: 1px dashed #DADADA;
  border-bottom: 0;
  color: #757575;
  margin-bottom: 0;
  padding: 2rem 0;
}
#file-drag-message [wk-icon=cloud-up] {
  font-size: 4rem;
}

.email-body-error {
  color: #E5202E;
  font-size: 0.87891rem;
  font-style: italic;
}

.action-item {
  pointer-events: none;
  opacity: 0;
}

.table-navbar .navbar-right > .grid {
  padding-right: 8px;
}
.table-navbar .wk-nav > li > a {
  background-color: transparent;
  font-size: 1.25rem;
}
.table-navbar .wk-nav > li > a > span::before {
  color: #005B92;
  font-size: 1.25em;
}
.table-navbar .wk-nav > li > a:hover {
  background-color: transparent;
}
.table-navbar .wk-nav > li > a:hover > span::before {
  color: #007AC3;
}

@media screen and (min-width: 75.0625rem) {
  table th {
    font-size: initial;
  }
}
@media screen and (max-width: 480px) {
  table th {
    font-size: 10px;
  }
}
table th > a {
  border-bottom: 1px solid transparent;
}
table th [wk-icon=file-down] {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (min-width: 75.0625rem) {
  table th [wk-icon=file-down] {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  table th [wk-icon=file-down] {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 480px) {
  table th [wk-icon=file-down] {
    font-size: 0.8rem;
  }
}
table th [wk-icon=file-down] [wk-icon=chevron-down],
table th [wk-icon=file-down] [wk-icon=chevron-up] {
  margin-left: -3px;
}
@media screen and (max-width: 767px) {
  table th [wk-icon=file-down] [wk-icon=chevron-down],
  table th [wk-icon=file-down] [wk-icon=chevron-up] {
    margin-left: -4px;
  }
}
table tr {
  min-height: 2.6rem;
}
table td,
table td a {
  word-wrap: break-word;
}
table [wk-icon] {
  font-size: 16px;
  font-size: initial;
}
@media screen and (min-width: 75.0625rem) {
  table {
    font-size: 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  table {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  table {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  table {
    font-size: 11px;
  }
}
@media screen and (max-width: 37.4375rem) {
  table {
    font-size: 11px;
  }
}
table tbody td .user-email-link {
  display: block;
  position: relative;
}
table tbody td .user-email-link > a {
  display: block;
  visibility: hidden;
  padding-left: 20%;
  width: 100%;
  position: absolute;
  text-align: left;
}
@media screen and (max-width: 480px) {
  table tbody td .user-email-link > a {
    padding-left: 10%;
    width: 100%;
  }
}
table tbody td .user-email-link > a:hover {
  border-bottom: 0;
}
table tbody tr:hover .user-email-link > a {
  visibility: visible;
}
table tbody [wk-icon] {
  background: #F6F6F6;
  color: #474747;
  font-size: 1.5rem;
  height: 120%;
  margin-left: 0;
  margin-right: 0;
  margin-top: -3px;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 480px) {
  table tbody [wk-icon] {
    font-size: 1rem;
  }
}
table tbody .file-actions {
  position: relative;
}
table tbody .file-actions [wk-icon] {
  height: 16px;
  height: initial;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  top: 9px;
  width: 100%;
}
@media screen and (min-width: 75.0625rem) {
  table tbody .file-actions [wk-icon] {
    right: 2%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  table tbody .file-actions [wk-icon] {
    right: 2%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  table tbody .file-actions [wk-icon] {
    right: 2%;
  }
}
@media screen and (max-width: 767px) {
  table tbody .file-actions [wk-icon] {
    right: 130%;
    width: 1.2em;
  }
}
@media screen and (max-width: 480px) {
  table tbody .file-actions [wk-icon] {
    right: 110%;
    top: 11px;
    width: 1.2em;
  }
}
table tbody .file-actions [wk-icon][wk-icon=file-cancel] {
  top: 10px;
}
table th span[wk-icon=chevron-down],
table th span[wk-icon=chevron-up] {
  font-size: 1rem;
  margin-left: -1px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  table th span[wk-icon=chevron-down],
  table th span[wk-icon=chevron-up] {
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: -1px;
  }
}
@media screen and (max-width: 767px) {
  table th span[wk-icon=chevron-down],
  table th span[wk-icon=chevron-up] {
    font-size: 0.6rem;
    font-weight: 600;
  }
}
@media screen and (max-width: 480px) {
  table th span[wk-icon=chevron-down],
  table th span[wk-icon=chevron-up] {
    font-size: 0.5rem;
  }
}

.table > thead > tr > th + th {
  padding-left: 0;
}
.table > tbody > tr > td + td {
  padding-left: 0;
}
.table > tbody > tr > td[headers=select-all-checkbox] {
  padding: 8px 0;
}
.table > tbody > tr > td[headers=name] {
  font-size: 14px;
  padding-left: 0;
  word-break: break-word;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .table > tbody > tr > td[headers=name] {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .table > tbody > tr > td[headers=name] {
    font-size: 12px;
  }
}
@media screen and (max-width: 37.4375rem) {
  .table > tbody > tr > td[headers=name] {
    font-size: 11px;
  }
}
.table > tbody > tr > td[headers=upload-date-time],
.table > tbody > tr > td[headers=size] {
  font-size: 15px;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .table > tbody > tr > td[headers=upload-date-time],
  .table > tbody > tr > td[headers=size] {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .table > tbody > tr > td[headers=upload-date-time],
  .table > tbody > tr > td[headers=size] {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .table > tbody > tr > td[headers=upload-date-time],
  .table > tbody > tr > td[headers=size] {
    font-size: 12px;
  }
}
@media screen and (max-width: 37.4375rem) {
  .table > tbody > tr > td[headers=upload-date-time],
  .table > tbody > tr > td[headers=size] {
    font-size: 11px;
  }
}
.table > tbody > tr > td[headers=download-count] {
  padding-right: 0;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .table > tbody > tr > td.hidden-sm.hidden-xs + td[headers=user-first-name] {
    padding-left: 8px;
  }
}
.table > tbody > tr > td[headers=user-is-selected] {
  padding: 8px 0;
  text-align: center;
}

@media screen and (min-width: 37.5rem) {
  .wk-search .wk-select-field {
    width: 14rem;
  }
}

#select-all-checkbox {
  padding: 8px 0;
  width: 3%;
}
#select-all-checkbox input[type=checkbox] {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 767px) {
  #select-all-checkbox {
    width: 4%;
  }
}
@media screen and (max-width: 480px) {
  #select-all-checkbox {
    width: 7%;
  }
}

#name {
  width: 45%;
}
.public-file-list #name, .received-files-list #name, .shared-files-list #name {
  width: 57%;
}

#upload-date-time {
  width: 13%;
}
@media screen and (max-width: 480px) {
  #upload-date-time {
    width: 18%;
  }
}

#size {
  width: 8%;
}
@media screen and (max-width: 480px) {
  #size {
    width: 12%;
  }
}

#author {
  width: 12%;
}
@media screen and (max-width: 480px) {
  #author {
    width: 16%;
  }
}

#assigned-to {
  width: 12%;
}
@media screen and (max-width: 480px) {
  #assigned-to {
    width: 14%;
  }
}

#download-count {
  padding-right: 0;
  text-align: left;
  width: 4%;
}
#download-count a {
  width: 100%;
}

#file-actions {
  text-align: center;
  width: 3%;
}

#file-list-table {
  table-layout: fixed;
  width: 100%;
}
#file-list-table input[type=checkbox] {
  padding: 0;
  margin: 2px 0 0 8px;
}
@media screen and (max-width: 767px) {
  #file-list-table input[type=checkbox] {
    margin-left: 2px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #file-list-table th input[type=checkbox] {
    margin-top: 3px;
  }
}
@media screen and (max-width: 767px) {
  #file-list-table th input[type=checkbox] {
    margin-top: 1px;
  }
}

.table > thead > tr > th {
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid #A3A3A3;
  text-transform: uppercase;
}

fs-user-list-user-type select {
  padding: 4px 4px 6px;
  margin-bottom: -6px;
  margin-top: -4px;
}

#user-first-name,
#user-last-name {
  width: 15%;
}

#user-email {
  width: 28%;
}

#user-actions {
  width: 14%;
}

#user-type {
  padding-bottom: 8px;
  padding-top: 8px;
  width: 14%;
}

#user-is-active,
#user-can-upload {
  text-align: center;
  width: 8%;
}

#user-list-table thead th + th {
  padding-left: 0;
}
#user-list-table tbody [wk-icon] {
  padding-right: 10px;
  width: 32px;
}
@media screen and (max-width: 480px) {
  #user-list-table tbody [wk-icon] {
    width: 22px;
  }
}

#sharing-options-table #user-is-selected {
  padding: 8px 0;
  text-align: center;
  width: 9%;
}
#sharing-options-table #user-first-name {
  width: 24%;
}
@media screen and (max-width: 991px) {
  #sharing-options-table #user-first-name {
    padding-left: 8px;
  }
}
#sharing-options-table #user-last-name {
  width: 24%;
}
#sharing-options-table #user-email {
  width: 43%;
}

#file_reassign_owner_table #name,
#file_list_table_2 #name_2 {
  width: 50%;
}

#file_reassign_owner_table #author,
#file_list_table_2 #assigned-to {
  width: 50%;
}

#file_reassign_owner_table td[headers=name],
#file_list_table_2 td[headers=name_2] {
  padding-left: 8px;
}

td[headers=user-is-active],
td[headers=user-can-upload] {
  text-align: center;
}

.list-item .user-actions .list-action, .list-item .file-actions .list-action {
  opacity: 0;
}
.list-item .user-actions .list-action-disabled, .list-item .file-actions .list-action-disabled {
  opacity: 0;
}
.list-item .user-actions .display-list-action, .list-item .file-actions .display-list-action {
  opacity: 1;
}
.list-item .user-actions .display-list-action-disabled, .list-item .file-actions .display-list-action-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.list-item .user-actions [wk-icon], .list-item .file-actions [wk-icon] {
  background: none;
}
.list-item:hover .user-actions .list-action, .list-item:hover .file-actions .list-action {
  opacity: 1;
}
.list-item:hover .user-actions .list-action-disabled, .list-item:hover .file-actions .list-action-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#file-bulk-delete-dialog .file-list {
  overflow-y: scroll;
  max-height: 200px;
}

.wk-footer-container nav {
  margin: 0 auto;
}
.wk-footer-container ul {
  padding-left: 0;
  padding-right: 0;
}
.wk-footer-container li {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  list-style-type: none;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .wk-footer-container li {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
  }
}
.wk-footer-container li:first-child {
  margin-left: 0;
}
.wk-footer-container li a {
  color: #FFFFFF;
}
.wk-footer-container li a:hover {
  color: #F6F6F6;
}

.wk-footer-copyright-container {
  color: #474747;
  font-weight: 400;
}

.one-trust-preference {
  margin-top: 10px;
}

[wk-icon] {
  font-size: 1.3rem;
  vertical-align: middle;
}

[wk-icon=spinner] {
  font-size: 3rem;
}

[wk-icon=info],
[wk-icon=check] {
  font-size: 1rem;
}

[wk-icon].disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.noborder {
  border: none;
}

.note-editor > *,
.note-editable > * {
  font-family: Helvetica, Ariel, sans-serif;
  font-size: 14px;
}

.page-title {
  display: block;
}
.page-title .plain {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  font-style: normal;
}
.page-title h1 {
  padding-left: 1rem;
}

.page-subtitle {
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-bold {
  font-weight: bold !important;
}

.text-normal {
  font-weight: 400;
}

.top-buffer {
  margin-top: 20px;
}

.tooltip-inner {
  max-width: 540px;
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.center-block {
  margin-top: 30px;
}

.input-group-addon {
  border: 0;
  background-color: transparent;
}

.alert-dismissable button.close {
  padding: 0 5px !important;
  text-shadow: none !important;
}

.div-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.fs-action-bar {
  z-index: 999;
}
.fs-addon {
  height: 4.6rem;
}
.fs-form-group {
  margin-bottom: 2rem;
}
.fs-loading-icon {
  left: 48.2vw;
  position: absolute;
  top: 155px;
  z-index: 1000;
}
.fs-search {
  width: 100%;
}
.fs-space {
  margin-bottom: 2rem;
}
.fs-firm-name {
  padding-bottom: 0.35rem;
}
.fs-firm-name:focus, .fs-firm-name:hover {
  border-bottom: 0;
}
.fs-text-overflow {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fs-flex {
  display: flex;
  flex-direction: row;
}
.fs-flex-small-block {
  display: flex;
  flex: 0 0 53px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
}
.fs-flex-small-block:first-child {
  margin-left: 0;
}
.fs-flex-medium-block {
  display: flex;
  flex: 0 0 80px;
  margin-left: 15px;
  margin-right: 0;
  margin-top: 12px;
}
.fs-flex-large-block {
  display: flex;
  flex: 0 0 126px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
}
.fs-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fs-input-group {
  height: 3.2rem;
  position: relative;
  top: -8px;
}
.fs-input-group-addon {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  padding-left: 0;
  text-align: left;
}
.fs-input-group .wk-field-header,
.fs-input-group .wk-field-body {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .fs-user-type .radio-inline {
    display: block;
    margin: 0.5rem 0 0.5rem 10px;
  }
}

@media screen and (min-width: 37.5rem) and (max-width: 65rem) {
  .wk-layout-centered-large .preview-area {
    max-width: 42.5rem;
  }
}
@media screen and (min-width: 37.5rem) and (max-width: 65rem) {
  .wk-layout-centered-large.is-wider {
    padding-left: 0;
    padding-right: 0;
    max-width: 45rem;
    font-size: 1.385rem;
  }
}

.wk-select-field > select.fs-flex-select {
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
  border-radius: 0;
  color: #474747;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: initial;
  display: flex;
  flex: 0 0 80px;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wk-select-field > select.fs-flex-select:focus {
  border-color: #474747;
}
.wk-select-field > select.fs-flex-select-large {
  flex: 1 0 180px;
  min-width: 180px;
}
.wk-select-field > select.fs-flex-select-large:focus {
  border-color: #474747;
}

.login-container {
  background: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 20px;
  width: 400px;
}

.wk-login-footer {
  font-size: 92%;
  line-height: 1.8;
  margin-bottom: 4rem;
  margin-top: 2rem;
  padding: 0.5em;
}

.flexbox {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  max-width: 1000px;
}
.flexbox-item {
  display: flex;
  flex: 1 1 120px;
  align-items: center;
  height: auto;
  margin-left: 5px;
  margin-right: 15px;
}
@media screen and (min-width: 75.0625rem) {
  .flexbox-item {
    height: 120px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .flexbox-item {
    height: 120px;
  }
}
.flexbox-item.firm-name {
  flex-wrap: wrap;
}
.flexbox-item:first-child {
  align-self: center;
  min-width: 120px;
}
@media screen and (min-width: 75.0625rem) {
  .flexbox-item:first-child {
    height: 120px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .flexbox-item:first-child {
    height: 120px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexbox-item:first-child {
    height: 120px;
    max-height: 16vw;
  }
}
@media screen and (max-width: 767px) {
  .flexbox-item:first-child {
    max-height: 20vw;
  }
}
@media screen and (max-width: 37.4375rem) {
  .flexbox-item:first-child {
    max-height: 20vw;
  }
}

.fs-error-graphic {
  margin-right: 20px;
  margin-top: 21px;
  width: 155px;
}
@media screen and (min-width: 37.5rem) {
  .fs-error-graphic {
    float: right;
  }
}
@media screen and (max-width: 37.4375rem) {
  .fs-error-graphic {
    background: #F6F6F6;
    margin-bottom: 10px;
    margin-right: 0;
    margin-top: -20px;
    max-height: 246px;
    padding-bottom: 30px;
    padding-top: 30px;
    width: initial;
  }
}

.file-name {
  display: inline-block;
  word-break: break-all;
  overflow-wrap: break-word;
}

.wk-pagination > li > a {
  padding: 0.5625rem 1.25rem 0.7rem 1.25rem;
}
.wk-pagination > li > a:hover, .wk-pagination > li > a:active {
  text-decoration: underline;
  border-bottom: none;
}

.fs-label {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 5px;
}

input:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

.email-template-header {
  margin-bottom: 0.25rem;
  margin-top: 1rem;
}
.email-template-header span {
  color: #007AC3;
  font-weight: 300;
  font-size: 1.44rem;
  font-style: italic;
  line-height: inherit;
}
.email-template-description {
  margin-bottom: 2rem;
}
.email-template-message .wk-alert {
  font-size: 16px;
}
.email-template-body .dropdown-menu > li {
  padding: 2px 10px;
  cursor: pointer;
}
.email-template-body .dropdown-menu > li:hover {
  background-color: #409BD2;
}
.email-template-body .dropdown-menu > li a {
  padding: 3px 20px;
}
.email-template-body .dropdown-menu > li a:hover {
  background-color: #F6F6F6;
}

.email-template-error {
  color: #E5202E;
  font-size: 0.87891rem;
  font-style: italic;
}

.fs-logo {
  opacity: 1;
  left: 0;
  transition: opacity 500ms ease-in-out;
}
.fs-logo:hover {
  opacity: 0.5;
}
.fs-firm-name {
  color: inherit;
}
.fs-firm-name:hover {
  color: #828282;
}
.fs-firm-name:active, .fs-firm-name:focus {
  color: inherit;
}

.firm-name {
  font-size: 3rem;
  line-height: 1;
  padding-left: 0;
}
@media screen and (max-width: 767px) {
  .firm-name {
    font-size: 2.5rem;
    margin-top: 1.325rem;
  }
}
@media screen and (max-width: 480px) {
  .firm-name {
    font-size: 8vw;
  }
}
.preview-area .firm-name {
  font-size: 2.57rem;
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .preview-area .firm-name {
    font-size: 2.14rem;
    margin-top: 0;
  }
}
@media screen and (max-width: 480px) {
  .preview-area .firm-name {
    font-size: 6.86vw;
    margin-top: 0;
  }
}

.preview-area {
  margin: 2.5rem 0;
  min-height: 150px;
  padding: 0.857rem 0;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .preview-area {
    max-height: 18vw;
  }
}
@media screen and (max-width: 767px) {
  .preview-area {
    max-height: 27.66vw;
  }
}
@media screen and (max-width: 37.4375rem) {
  .preview-area {
    height: 120px;
    max-height: 30.23vw;
    min-height: 3rem;
    padding: 0.536rem 0.214rem;
  }
}
.preview-area .logo-mark {
  height: auto;
  max-height: 102px;
}
.preview-area .flexbox {
  align-items: center;
  height: 8.577vw;
  margin-left: 4.28px;
  margin-right: 4.28px;
}
@media screen and (min-width: 75.0625rem) {
  .preview-area .flexbox {
    height: 102px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .preview-area .flexbox {
    height: 102px;
  }
}
.preview-area .flexbox-item {
  align-self: flex-start;
  flex: 1 1 120px;
  transition: all 500ms;
}
.preview-area .flexbox-item:first-child {
  height: 8.577vw;
}
@media screen and (min-width: 75.0625rem) {
  .preview-area .flexbox-item:first-child {
    height: 102px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .preview-area .flexbox-item:first-child {
    height: 102px;
    max-height: 8vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .preview-area .flexbox-item:first-child {
    height: 102px;
    max-height: 13.72vw;
  }
}
@media screen and (max-width: 767px) {
  .preview-area .flexbox-item:first-child {
    max-height: 17.15vw;
  }
}
@media screen and (max-width: 37.4375rem) {
  .preview-area .flexbox-item:first-child {
    max-height: 17.15vw;
  }
}
.preview-area .flexbox-item:nth-child(2) {
  align-self: center;
  height: auto;
  justify-content: flex-start;
}

.firm-url-control .valid {
  color: #85BC20;
}
.firm-url-control .invalid {
  color: #E5202E;
}

.dropdown.open .dropdown-menu {
  display: block;
}

.table-hover tbody tr:hover {
  background: #F6F6F6;
}

.growl-container > .growl-item {
  padding-right: 10px;
}

.growl-container button.close {
  margin-top: -4px;
}

.note-editor .dropdown-toggle::after {
  all: unset;
}

.note-editor .note-dropdown-menu {
  box-sizing: content-box;
}

.note-editor .note-modal-footer {
  box-sizing: content-box;
}

.note-dropdown-item:hover {
  border-bottom: 0;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: transparent;
}

.wk-text-overflow {
  display: revert;
}

.wk-navbar .wk-navbar-container .wk-nav a:hover {
  color: #fff;
}

.link-dialog > .note-modal-content > .note-modal-footer {
  box-sizing: border-box;
  height: 70px;
  border-top: 1px solid #e5e5e5;
}