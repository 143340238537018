/* Include this file in your html if you are using the CSP mode. */
@charset "UTF-8";

.ng-cloak, .x-ng-cloak, .ng-hide,
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak] {
    display: none !important;
}

ng\:form {
    display: block;
}