﻿/// <reference path="_config.scss" />

.email-template {
    &-header {
        margin-bottom: 0.25rem;
        margin-top: 1rem;
        span {
            color: $wk-blue;
            font-weight: 300;
            font-size: 1.44rem;
            font-style: italic;
            line-height: inherit;
        }
    }
    &-description {
        margin-bottom: 2rem;
    }
    &-message {
        .wk-alert {
            font-size: 16px;
        }
    }
    &-body {
        .dropdown-menu > li {
            padding: 2px 10px;
            cursor: pointer;
            &:hover {
                background-color: $wk-blue-tint1;
            }
            a {
                padding: 3px 20px;
                &:hover {
                    background-color: $wk-gray-tint6;
                }
            }
        }
    }
}

.email-template-error {
    color: $wk-red;
    font-size: 0.87891rem;
    font-style: italic;
}